/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EpaForm,
  EpaFormFile,
  EpaFormFileCreate,
  EpaFormFileCreateRequest,
  EpaFormFileOperationRequest,
  EpaFormRequest,
  EpaOperatorNotification,
  EpaOperatorNotificationRequest,
  EpaSepStatsBarResponse,
  PaginatedEpaOperatorNotificationList,
  PatchedEpaFormRequest,
  PatchedEpaOperatorNotificationRequest,
  SepEventStats,
} from '../models/index';
import {
    EpaFormFromJSON,
    EpaFormToJSON,
    EpaFormFileFromJSON,
    EpaFormFileToJSON,
    EpaFormFileCreateFromJSON,
    EpaFormFileCreateToJSON,
    EpaFormFileCreateRequestFromJSON,
    EpaFormFileCreateRequestToJSON,
    EpaFormFileOperationRequestFromJSON,
    EpaFormFileOperationRequestToJSON,
    EpaFormRequestFromJSON,
    EpaFormRequestToJSON,
    EpaOperatorNotificationFromJSON,
    EpaOperatorNotificationToJSON,
    EpaOperatorNotificationRequestFromJSON,
    EpaOperatorNotificationRequestToJSON,
    EpaSepStatsBarResponseFromJSON,
    EpaSepStatsBarResponseToJSON,
    PaginatedEpaOperatorNotificationListFromJSON,
    PaginatedEpaOperatorNotificationListToJSON,
    PatchedEpaFormRequestFromJSON,
    PatchedEpaFormRequestToJSON,
    PatchedEpaOperatorNotificationRequestFromJSON,
    PatchedEpaOperatorNotificationRequestToJSON,
    SepEventStatsFromJSON,
    SepEventStatsToJSON,
} from '../models/index';

export interface EpaSepFormFilesCreateRequest {
    id: string;
    epaFormFileCreateRequest: EpaFormFileCreateRequest;
}

export interface EpaSepFormFilesDestroyRequest {
    fileId: string;
    id: string;
}

export interface EpaSepFormFilesListRequest {
    id: string;
    ordering?: string;
}

export interface EpaSepFormFilesUpdateRequest {
    id: string;
    epaFormFileOperationRequest: EpaFormFileOperationRequest;
}

export interface EpaSepFormListRequest {
    ordering?: string;
}

export interface EpaSepFormPartialUpdateRequest {
    id: string;
    patchedEpaFormRequest?: PatchedEpaFormRequest;
}

export interface EpaSepFormRetrieveRequest {
    id: string;
}

export interface EpaSepFormUpdateRequest {
    id: string;
    epaFormRequest: EpaFormRequest;
}

export interface EpaSepNotificationsEpaSepStatsRetrieveRequest {
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    hasDeadline?: boolean;
    notificationReason?: Array<EpaSepNotificationsEpaSepStatsRetrieveNotificationReasonEnum>;
    ordering?: string;
    search?: string;
    status?: Array<EpaSepNotificationsEpaSepStatsRetrieveStatusEnum>;
}

export interface EpaSepNotificationsListRequest {
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    hasDeadline?: boolean;
    notificationReason?: Array<EpaSepNotificationsListNotificationReasonEnum>;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
    status?: Array<EpaSepNotificationsListStatusEnum>;
}

export interface EpaSepNotificationsPartialUpdateRequest {
    id: string;
    patchedEpaOperatorNotificationRequest?: PatchedEpaOperatorNotificationRequest;
}

export interface EpaSepNotificationsRetrieveRequest {
    id: string;
}

export interface EpaSepNotificationsSepEventStatsListRequest {
    detectionDateRangeAfter?: Date;
    detectionDateRangeBefore?: Date;
    hasDeadline?: boolean;
    notificationReason?: Array<EpaSepNotificationsSepEventStatsListNotificationReasonEnum>;
    ordering?: string;
    search?: string;
    status?: Array<EpaSepNotificationsSepEventStatsListStatusEnum>;
}

export interface EpaSepNotificationsUpdateRequest {
    id: string;
    epaOperatorNotificationRequest: EpaOperatorNotificationRequest;
}

/**
 * 
 */
export class EpaSepApi extends runtime.BaseAPI {

    /**
     * Retrieves file list for this EPA Form.
     */
    async epaSepFormFilesCreateRaw(requestParameters: EpaSepFormFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EpaFormFileCreate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling epaSepFormFilesCreate().'
            );
        }

        if (requestParameters['epaFormFileCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'epaFormFileCreateRequest',
                'Required parameter "epaFormFileCreateRequest" was null or undefined when calling epaSepFormFilesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/form/{id}/files/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EpaFormFileCreateRequestToJSON(requestParameters['epaFormFileCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EpaFormFileCreateFromJSON(jsonValue));
    }

    /**
     * Retrieves file list for this EPA Form.
     */
    async epaSepFormFilesCreate(requestParameters: EpaSepFormFilesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EpaFormFileCreate> {
        const response = await this.epaSepFormFilesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a file from this EPA Form
     */
    async epaSepFormFilesDestroyRaw(requestParameters: EpaSepFormFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['fileId'] == null) {
            throw new runtime.RequiredError(
                'fileId',
                'Required parameter "fileId" was null or undefined when calling epaSepFormFilesDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling epaSepFormFilesDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['fileId'] != null) {
            queryParameters['file_id'] = requestParameters['fileId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/form/{id}/files/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a file from this EPA Form
     */
    async epaSepFormFilesDestroy(requestParameters: EpaSepFormFilesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.epaSepFormFilesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves file list for this EPA Form.
     */
    async epaSepFormFilesListRaw(requestParameters: EpaSepFormFilesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EpaFormFile>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling epaSepFormFilesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/form/{id}/files/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EpaFormFileFromJSON));
    }

    /**
     * Retrieves file list for this EPA Form.
     */
    async epaSepFormFilesList(requestParameters: EpaSepFormFilesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EpaFormFile>> {
        const response = await this.epaSepFormFilesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves file list for this EPA Form.
     */
    async epaSepFormFilesUpdateRaw(requestParameters: EpaSepFormFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EpaFormFile>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling epaSepFormFilesUpdate().'
            );
        }

        if (requestParameters['epaFormFileOperationRequest'] == null) {
            throw new runtime.RequiredError(
                'epaFormFileOperationRequest',
                'Required parameter "epaFormFileOperationRequest" was null or undefined when calling epaSepFormFilesUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/form/{id}/files/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EpaFormFileOperationRequestToJSON(requestParameters['epaFormFileOperationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EpaFormFileFromJSON(jsonValue));
    }

    /**
     * Retrieves file list for this EPA Form.
     */
    async epaSepFormFilesUpdate(requestParameters: EpaSepFormFilesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EpaFormFile> {
        const response = await this.epaSepFormFilesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepFormListRaw(requestParameters: EpaSepFormListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EpaForm>>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/form/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EpaFormFromJSON));
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepFormList(requestParameters: EpaSepFormListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EpaForm>> {
        const response = await this.epaSepFormListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepFormPartialUpdateRaw(requestParameters: EpaSepFormPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EpaForm>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling epaSepFormPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/form/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedEpaFormRequestToJSON(requestParameters['patchedEpaFormRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EpaFormFromJSON(jsonValue));
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepFormPartialUpdate(requestParameters: EpaSepFormPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EpaForm> {
        const response = await this.epaSepFormPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepFormRetrieveRaw(requestParameters: EpaSepFormRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EpaForm>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling epaSepFormRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/form/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EpaFormFromJSON(jsonValue));
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepFormRetrieve(requestParameters: EpaSepFormRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EpaForm> {
        const response = await this.epaSepFormRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepFormUpdateRaw(requestParameters: EpaSepFormUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EpaForm>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling epaSepFormUpdate().'
            );
        }

        if (requestParameters['epaFormRequest'] == null) {
            throw new runtime.RequiredError(
                'epaFormRequest',
                'Required parameter "epaFormRequest" was null or undefined when calling epaSepFormUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/form/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EpaFormRequestToJSON(requestParameters['epaFormRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EpaFormFromJSON(jsonValue));
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepFormUpdate(requestParameters: EpaSepFormUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EpaForm> {
        const response = await this.epaSepFormUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns statistics to de displayed in a dashboard style.
     */
    async epaSepNotificationsEpaSepStatsRetrieveRaw(requestParameters: EpaSepNotificationsEpaSepStatsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EpaSepStatsBarResponse>> {
        const queryParameters: any = {};

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['hasDeadline'] != null) {
            queryParameters['has_deadline'] = requestParameters['hasDeadline'];
        }

        if (requestParameters['notificationReason'] != null) {
            queryParameters['notification_reason'] = requestParameters['notificationReason'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/notifications/epa_sep_stats/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EpaSepStatsBarResponseFromJSON(jsonValue));
    }

    /**
     * Returns statistics to de displayed in a dashboard style.
     */
    async epaSepNotificationsEpaSepStatsRetrieve(requestParameters: EpaSepNotificationsEpaSepStatsRetrieveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EpaSepStatsBarResponse> {
        const response = await this.epaSepNotificationsEpaSepStatsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepNotificationsListRaw(requestParameters: EpaSepNotificationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEpaOperatorNotificationList>> {
        const queryParameters: any = {};

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['hasDeadline'] != null) {
            queryParameters['has_deadline'] = requestParameters['hasDeadline'];
        }

        if (requestParameters['notificationReason'] != null) {
            queryParameters['notification_reason'] = requestParameters['notificationReason'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/notifications/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEpaOperatorNotificationListFromJSON(jsonValue));
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepNotificationsList(requestParameters: EpaSepNotificationsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEpaOperatorNotificationList> {
        const response = await this.epaSepNotificationsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepNotificationsPartialUpdateRaw(requestParameters: EpaSepNotificationsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EpaOperatorNotification>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling epaSepNotificationsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/notifications/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedEpaOperatorNotificationRequestToJSON(requestParameters['patchedEpaOperatorNotificationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EpaOperatorNotificationFromJSON(jsonValue));
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepNotificationsPartialUpdate(requestParameters: EpaSepNotificationsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EpaOperatorNotification> {
        const response = await this.epaSepNotificationsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepNotificationsRetrieveRaw(requestParameters: EpaSepNotificationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EpaOperatorNotification>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling epaSepNotificationsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/notifications/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EpaOperatorNotificationFromJSON(jsonValue));
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepNotificationsRetrieve(requestParameters: EpaSepNotificationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EpaOperatorNotification> {
        const response = await this.epaSepNotificationsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Computes EPA\'s SEP Statistics for main plot.
     */
    async epaSepNotificationsSepEventStatsListRaw(requestParameters: EpaSepNotificationsSepEventStatsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SepEventStats>>> {
        const queryParameters: any = {};

        if (requestParameters['detectionDateRangeAfter'] != null) {
            queryParameters['detection_date_range_after'] = (requestParameters['detectionDateRangeAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['detectionDateRangeBefore'] != null) {
            queryParameters['detection_date_range_before'] = (requestParameters['detectionDateRangeBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['hasDeadline'] != null) {
            queryParameters['has_deadline'] = requestParameters['hasDeadline'];
        }

        if (requestParameters['notificationReason'] != null) {
            queryParameters['notification_reason'] = requestParameters['notificationReason'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/notifications/sep_event_stats/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SepEventStatsFromJSON));
    }

    /**
     * Computes EPA\'s SEP Statistics for main plot.
     */
    async epaSepNotificationsSepEventStatsList(requestParameters: EpaSepNotificationsSepEventStatsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SepEventStats>> {
        const response = await this.epaSepNotificationsSepEventStatsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepNotificationsUpdateRaw(requestParameters: EpaSepNotificationsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EpaOperatorNotification>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling epaSepNotificationsUpdate().'
            );
        }

        if (requestParameters['epaOperatorNotificationRequest'] == null) {
            throw new runtime.RequiredError(
                'epaOperatorNotificationRequest',
                'Required parameter "epaOperatorNotificationRequest" was null or undefined when calling epaSepNotificationsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/epa-sep/notifications/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EpaOperatorNotificationRequestToJSON(requestParameters['epaOperatorNotificationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EpaOperatorNotificationFromJSON(jsonValue));
    }

    /**
     * Viewset to retrieve public
     */
    async epaSepNotificationsUpdate(requestParameters: EpaSepNotificationsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EpaOperatorNotification> {
        const response = await this.epaSepNotificationsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const EpaSepNotificationsEpaSepStatsRetrieveNotificationReasonEnum = {
    EpaNotification: 'EPA_NOTIFICATION',
    MatchedInfrastructure: 'MATCHED_INFRASTRUCTURE',
    UserCreated: 'USER_CREATED'
} as const;
export type EpaSepNotificationsEpaSepStatsRetrieveNotificationReasonEnum = typeof EpaSepNotificationsEpaSepStatsRetrieveNotificationReasonEnum[keyof typeof EpaSepNotificationsEpaSepStatsRetrieveNotificationReasonEnum];
/**
 * @export
 */
export const EpaSepNotificationsEpaSepStatsRetrieveStatusEnum = {
    Complete: 'COMPLETE',
    Created: 'CREATED',
    InvestigationStarted: 'INVESTIGATION_STARTED',
    OperatorNotified: 'OPERATOR_NOTIFIED',
    SubmittedToEpa: 'SUBMITTED_TO_EPA'
} as const;
export type EpaSepNotificationsEpaSepStatsRetrieveStatusEnum = typeof EpaSepNotificationsEpaSepStatsRetrieveStatusEnum[keyof typeof EpaSepNotificationsEpaSepStatsRetrieveStatusEnum];
/**
 * @export
 */
export const EpaSepNotificationsListNotificationReasonEnum = {
    EpaNotification: 'EPA_NOTIFICATION',
    MatchedInfrastructure: 'MATCHED_INFRASTRUCTURE',
    UserCreated: 'USER_CREATED'
} as const;
export type EpaSepNotificationsListNotificationReasonEnum = typeof EpaSepNotificationsListNotificationReasonEnum[keyof typeof EpaSepNotificationsListNotificationReasonEnum];
/**
 * @export
 */
export const EpaSepNotificationsListStatusEnum = {
    Complete: 'COMPLETE',
    Created: 'CREATED',
    InvestigationStarted: 'INVESTIGATION_STARTED',
    OperatorNotified: 'OPERATOR_NOTIFIED',
    SubmittedToEpa: 'SUBMITTED_TO_EPA'
} as const;
export type EpaSepNotificationsListStatusEnum = typeof EpaSepNotificationsListStatusEnum[keyof typeof EpaSepNotificationsListStatusEnum];
/**
 * @export
 */
export const EpaSepNotificationsSepEventStatsListNotificationReasonEnum = {
    EpaNotification: 'EPA_NOTIFICATION',
    MatchedInfrastructure: 'MATCHED_INFRASTRUCTURE',
    UserCreated: 'USER_CREATED'
} as const;
export type EpaSepNotificationsSepEventStatsListNotificationReasonEnum = typeof EpaSepNotificationsSepEventStatsListNotificationReasonEnum[keyof typeof EpaSepNotificationsSepEventStatsListNotificationReasonEnum];
/**
 * @export
 */
export const EpaSepNotificationsSepEventStatsListStatusEnum = {
    Complete: 'COMPLETE',
    Created: 'CREATED',
    InvestigationStarted: 'INVESTIGATION_STARTED',
    OperatorNotified: 'OPERATOR_NOTIFIED',
    SubmittedToEpa: 'SUBMITTED_TO_EPA'
} as const;
export type EpaSepNotificationsSepEventStatsListStatusEnum = typeof EpaSepNotificationsSepEventStatsListStatusEnum[keyof typeof EpaSepNotificationsSepEventStatsListStatusEnum];
