import {
    ColumnDef,
    Header,
    HeaderGroup,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    PlusIcon,
} from "@heroicons/react/24/outline";
import { ArrowsUpDownIcon } from "@heroicons/react/20/solid";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { BaseTextSearchFilter } from "./TableFilters";
import { PrimaryButton } from "../../ui/Buttons";
import { filterStateToApiParams, tableStateFamily } from "./state";
import { useAtom } from "jotai";
import { camelToSnakeCase } from "../../utils/string";

const DEFAULT_PAGE_SIZES = [5, 10, 20, 50];

interface TableFilterSortProps {
    dataName: string;
    header: Header<any, any>;
}

const TableFilter = (props: TableFilterSortProps) => {
    // Extract columnDef and filter definition parameters
    const columnDef = props.header.column.columnDef;
    const filterKey = columnDef.meta?.filteringKey || columnDef.id;

    // Retrieve table state and filter values
    const [tableState, setTableState] = useAtom(
        tableStateFamily({ tableId: props.dataName }),
    );
    const value = tableState.filters[filterKey];
    const setValue = useCallback((newValue: any) => {
        setTableState((ts) => {
            return {
                ...ts,
                filters: {
                    ...ts.filters,
                    [filterKey]: newValue,
                },
            };
        });
    }, []);

    if (columnDef.meta && columnDef.meta.customFilterWidget) {
        return (
            <columnDef.meta.customFilterWidget
                isFiltered={!!value}
                filterValue={value}
                setFilterValue={setValue}
            />
        );
    }

    return (
        <BaseTextSearchFilter
            isFiltered={!!value}
            filterValue={value as string}
            setFilterValue={setValue}
        />
    );
};

const TableSort = (props: TableFilterSortProps) => {
    // Extract columnDef and filter definition parameters
    const columnDef = props.header.column.columnDef;
    const sortKey =
        columnDef.meta?.sortingKey || camelToSnakeCase(columnDef.id);

    // Retrieve table state and filter values
    const [tableState, setTableState] = useAtom(
        tableStateFamily({ tableId: props.dataName }),
    );
    const isSorted = tableState.sorting && tableState.sorting.id === sortKey;
    const setSorting = useCallback(() => {
        setTableState((ts) => {
            return {
                ...ts,
                sorting: {
                    id: sortKey,
                    desc:
                        ts.sorting && ts.sorting.id === sortKey
                            ? !ts.sorting.desc
                            : false,
                },
            };
        });
    }, []);

    return (
        <button className="ml-2" onClick={setSorting}>
            <ArrowsUpDownIcon
                className={` h-4 w-4 ${isSorted ? "" : "text-gray-400"}`}
            />
        </button>
    );
};

interface TableHeaderProps<T> {
    dataName: string;
    headers: HeaderGroup<T>[];
}

const TableHeader = <T,>(props: TableHeaderProps<T>) => (
    <thead className="bg-ae-gray-100 text-left text-sm font-bold text-ae-blue-900 border-b">
        {props.headers.map((headerGroup) => (
            <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                    <th
                        key={header.id}
                        className="px-4 py-3 hover:bg-neutral-200"
                        style={{
                            width:
                                header.getSize() === Number.MAX_SAFE_INTEGER
                                    ? "auto"
                                    : header.getSize(),
                        }}
                    >
                        {header.isPlaceholder ? null : (
                            <span className="flex items-center">
                                {header.column.getCanFilter() && (
                                    <TableFilter
                                        dataName={props.dataName}
                                        header={header}
                                    />
                                )}
                                {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                )}
                                {header.column.getCanSort() && (
                                    <TableSort
                                        dataName={props.dataName}
                                        header={header}
                                    />
                                )}
                            </span>
                        )}
                    </th>
                ))}
            </tr>
        ))}
    </thead>
);

interface TableFooterProps {
    loadingData: boolean;
    currentPage: number;
    pageCount: number;
    nextPage: () => void;
    nextPageEnabled: boolean;
    previousPage: () => void;
    previousPageEnabled: boolean;
    defaultPageSize: number;
    selectedPageSize: number;
    onSelectPageSize: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const TableFooter = (props: TableFooterProps) => (
    <div className="border-t rounded-b bg-white border-slate-200 flex items-center justify-between w-full text-sm font-medium p-3 text-ae-blue-900">
        <div className="flex items-center gap-3 mr-6">
            Rows per page:
            <select
                value={props.selectedPageSize}
                onChange={props.onSelectPageSize}
                className="h-9 text-sm rounded border-neutral-300 py-1"
            >
                {DEFAULT_PAGE_SIZES.map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                        {pageSize}
                    </option>
                ))}
                {props.defaultPageSize &&
                    !DEFAULT_PAGE_SIZES.includes(props.defaultPageSize) && (
                        <option
                            key={props.defaultPageSize}
                            value={props.defaultPageSize}
                        >
                            {props.defaultPageSize}
                        </option>
                    )}
            </select>
        </div>

        <div className="p-2">
            {props.loadingData && (
                <div className="flex items-center">
                    Fetching data...
                    <ArrowPathIcon className="ml-3 h-4 animate-spin" />
                </div>
            )}
        </div>

        <div className="flex items-center">
            <div className="mr-4">
                Page {props.currentPage} of {props.pageCount}
            </div>
            <button
                className={`mr-2 p-1 rounded-md ${
                    props.previousPageEnabled &&
                    "hover:text-white hover:bg-ae-blue-900"
                }`}
                onClick={props.previousPage}
                disabled={!props.previousPageEnabled}
            >
                <ChevronLeftIcon className="h-5" />
            </button>
            <button
                className={`p-1 rounded-md ${
                    props.nextPageEnabled &&
                    "hover:text-white hover:bg-ae-blue-900"
                }`}
                onClick={props.nextPage}
                disabled={!props.nextPageEnabled}
            >
                <ChevronRightIcon className="h-5" />
            </button>
        </div>
    </div>
);

interface RowActionButtonsProps {
    actions: {
        fn: () => void;
        icon: ReactNode;
        tooltip?: string;
    }[];
}

export const RowActionButtons = (props: RowActionButtonsProps) => {
    return (
        <div className="w-full flex items-center justify-end gap-1 -my-2">
            {props.actions.map((action, index) => {
                return (
                    <div
                        key={index}
                        className="group relative flex items-center"
                    >
                        <button
                            onClick={action.fn}
                            className="h-9 w-9 flex items-center justify-center text-ae-blue-900 p-1 rounded hover:border-2 border-ae-gray-300"
                        >
                            {action.icon}
                        </button>
                        {action.tooltip && (
                            <span className="z-0 absolute right-full px-3 py-1 mr-3 text-sm bg-white border border-white rounded drop-shadow-md opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap">
                                {action.tooltip}
                                <svg
                                    className="absolute top-1/2 -right-2 h-2 w-2 fill-current text-white transform -translate-y-1/2"
                                    viewBox="0 0 20 20"
                                >
                                    <polygon points="0,0 20,10 0,20" />
                                </svg>
                            </span>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

interface DataTableV3Props<T> {
    // TODO: improve typing
    dataName: string;
    columns: ColumnDef<T, any>[];
    fetchFunction: any;
    sortable: boolean;
    filterable: boolean;
    extraFilters: {
        [key: string]: any;
    };
    onClickRow?: (string) => void;
    defaultPageSize?: number;
    partialUpdate?: (id: string, value: Partial<T>) => Promise<void>;
    deleteRow?: (id: string) => Promise<void>;
    createRow?: () => void;
    getRowId?: (row: T) => any;
}

/**
 * New data table, using atoms for state management.
 */
export const DataTableV3 = <T,>(props: DataTableV3Props<T>) => {
    const defaultData = useMemo(() => [], []);
    const { extraFilters } = props;
    const [tableState, setTableState] = useAtom(
        tableStateFamily({
            tableId: props.dataName,
        }),
    );

    const getRowId = props.getRowId ?? ((row: any) => row.id);

    /**
     * Query builder & fetch/update functions
     */
    const fetchDataOptions = useMemo(() => {
        return {
            ...extraFilters,
            ...filterStateToApiParams(tableState.filters),
            ordering: tableState.sorting
                ? `${tableState.sorting.desc ? "-" : ""}${
                      tableState.sorting.id
                  }`
                : undefined,
            page: tableState.pagination.pageIndex + 1,
            pageSize: tableState.pagination.pageSize,
        };
    }, [tableState, extraFilters]);

    const queryClient = useQueryClient();
    const dataQuery = useQuery(
        [props.dataName, fetchDataOptions],
        () => props.fetchFunction(fetchDataOptions),
        {
            keepPreviousData: true,
            retry: 1,
        },
    );

    // Handle when pages don't exist, resetting the pagination
    // to 0 when the invalid page error is found.
    useEffect(() => {
        const handleError = async () => {
            const response = await (dataQuery.error as any).response.json();
            if (response.detail === "Invalid page.") {
                setTableState((ts) => {
                    return {
                        ...ts,
                        pagination: {
                            ...ts.pagination,
                            pageIndex: 0,
                        },
                    };
                });
            }
        };
        if (dataQuery.isError) {
            handleError();
        }
    }, [dataQuery]);

    // TODO: Add types
    const partialUpdate = useMutation<any, any, any, any>(
        ["partialUpdate"],
        async (params) => {
            return props.partialUpdate(params.itemId, params.value);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: [props.dataName, fetchDataOptions],
                });
            },
        },
    );

    // TODO: Add types
    const deleteRow = useMutation<any, any, any, any>(
        ["deleteRow"],
        async (params) => {
            return props.deleteRow(params.itemId);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: [props.dataName, fetchDataOptions],
                });
            },
        },
    );

    /**
     * Table configuration
     */
    const table = useReactTable({
        data: dataQuery.data?.results ?? defaultData,
        columns: props.columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (originalRow: any) => `row_${getRowId(originalRow)}`,
        defaultColumn: {
            minSize: 0,
            size: Number.MAX_SAFE_INTEGER,
            maxSize: Number.MAX_SAFE_INTEGER,
        },
        // Pagination
        pageCount: dataQuery.data?.numPages,
        onPaginationChange: (updater: any) =>
            setTableState((ts) => {
                return {
                    ...ts,
                    pagination: updater(ts.pagination),
                };
            }),
        manualPagination: true,
        state: {
            pagination: tableState.pagination,
            columnVisibility: tableState.columnVisibility,
        },
        // Update row support
        meta: {
            updateData: (rowIndex, itemId, value) => {
                return partialUpdate.mutateAsync({ rowIndex, itemId, value });
            },
            deleteRow: (rowIndex, itemId) => {
                return deleteRow.mutateAsync({ rowIndex, itemId });
            },
            refresh: async () => {
                dataQuery.refetch();
            },
        },
        // Sorting
        enableSorting: props.sortable,
        // Filtering
        enableFilters: props.filterable,
        manualFiltering: true,
        enableColumnFilters: true,
    });

    return (
        <div className="flex-grow flex flex-col text-base overflow-x-scroll">
            <div className="min-h-56 max-w-full">
                <table className="table-auto w-full">
                    <TableHeader
                        dataName={props.dataName}
                        headers={table.getHeaderGroups()}
                    />

                    {/* Table body */}
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <tr
                                key={row.id}
                                onClick={() => {
                                    if (props.onClickRow) {
                                        props.onClickRow(
                                            (row.original as any).id,
                                        );
                                    }
                                }}
                                className={`
                                    border-y border-y-ae-neutral-200 hover:bg-ae-neutral-100
                                    h-14 group/tableRow
                                    ${
                                        props.onClickRow
                                            ? "hover:cursor-pointer"
                                            : ""
                                    }
                                `}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <td
                                        key={cell.id}
                                        className="py-3 px-4 text-sm font-normal text-ae-blue-900"
                                        style={{
                                            width:
                                                cell.column.getSize() ===
                                                Number.MAX_SAFE_INTEGER
                                                    ? "auto"
                                                    : cell.column.getSize(),
                                            maxWidth: cell.column.getSize(),
                                        }}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {props.createRow && (
                            <td
                                colSpan={props.columns.length}
                                className="w-full border-y border-y-ae-neutral-200 h-14"
                            >
                                <div className="w-full flex items-center justify-center">
                                    <PrimaryButton onClick={props.createRow}>
                                        <PlusIcon className="w-4" />
                                        Add new
                                    </PrimaryButton>
                                </div>
                            </td>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Table footer area */}
            <TableFooter
                loadingData={dataQuery.isFetching}
                currentPage={table.getState().pagination.pageIndex + 1}
                pageCount={table.getPageCount()}
                previousPage={() => table.previousPage()}
                previousPageEnabled={table.getCanPreviousPage()}
                nextPage={() => table.nextPage()}
                nextPageEnabled={table.getCanNextPage()}
                onSelectPageSize={(e) => {
                    table.setPageSize(Number(e.target.value));
                    table.setPageIndex(0);
                }}
                defaultPageSize={props.defaultPageSize}
                selectedPageSize={table.getState().pagination.pageSize}
            />
        </div>
    );
};
