import { useQuery } from "@tanstack/react-query";
import { useEpaSepApiClient } from "../../../hooks";
import { FullscreenModalBase } from "../../../ui/Modals";
import { FormSection, SubmitToCdxNotice } from "./ui";
import { ReactNode, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { CoordinatesField } from "../../../ui/GenericFields";
import { PrimaryButton, SecondaryButton } from "../../../ui/Buttons";
import { useEpaForm } from "./form";
import { EpaForm } from "../../../apiClient/generated";
import { LoadingIndicator } from "../../MapV2/ui/LoadingIndicator";
import {
    CheckboxField,
    DateField,
    FacilityInformationIdField,
    TextArea,
    TextField,
    TimeField,
    YesNoQuestion,
} from "./fields";
import { FormProvider } from "react-hook-form";
import * as turf from "@turf/turf";
import { EpaFormUploadField } from "./FileUploads";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronsDown,
    faChevronsUp,
    faX,
} from "@fortawesome/pro-light-svg-icons";

interface EpaSepNotificationInformationProps {
    epaNotificationId: string;
    expanded: boolean;
}

const EpaSepNotificationInformation = (
    props: EpaSepNotificationInformationProps,
) => {
    const apiClient = useEpaSepApiClient();
    const notificationQuery = useQuery({
        queryKey: ["epaSepNotification", props.epaNotificationId],
        queryFn: async () => {
            return await apiClient.epaSepNotificationsRetrieve({
                id: props.epaNotificationId,
            });
        },
        refetchOnWindowFocus: false,
        enabled: !!props.epaNotificationId,
        staleTime: 0,
    });

    const data = useMemo((): {
        [key: string]: string | ReactNode;
    } => {
        if (!notificationQuery.data) {
            return {};
        }

        const dt = notificationQuery.data;
        const deadline = new Date(
            dt.notification?.notificationDate?.getTime() +
                1000 * 60 * 60 * 24 * 15,
        );
        const diffInDays = Math.floor(
            (deadline.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24),
        );

        let distance = "-";
        if (dt.nearestSite?.location) {
            const d = turf.distance(
                dt.notification.location,
                dt.nearestSite.location,
                { units: "meters" },
            );
            distance = `${d.toFixed(2)} m`;
        }
        const d = {
            "Date of detection": DateTime.fromJSDate(
                dt.notification.detectionDate,
            )
                .setZone("utc")
                .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
            "EPA Identified site": dt.epaIdentifiedSite,
            "Date of notification": DateTime.fromJSDate(
                dt.notification?.notificationDate,
            )
                .setZone("utc")
                .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
            "Nearest site in AERSHED": dt.nearestSite?.siteName || "-",
            "Time remaining":
                diffInDays >= 0 ? (
                    `${diffInDays} days`
                ) : (
                    <span className="text-red-500">Overdue</span>
                ),
            Distance: distance,
            "Emission rate": `${dt.notification.detectedRate / 1000} kg/h`,
            Assignee: dt.assigneeName,
            "Provider name": dt.notification.providerName,
            "Notification Report ID": dt.notification.notificationReportId,
            "Notifier name": dt.notification.notifierName,
            "Latitude/Longitude": (
                <CoordinatesField
                    coordinates={dt.notification.location.coordinates}
                    allowCopy
                />
            ),
        };
        if (props.expanded) {
            d["Wind Speed"] = `${dt.notification?.windSpeed || "-"} m/s`;
            d["Wind Direction"] =
                `${dt.notification?.windDirection || "-"} degrees`;
            d["Temperature"] = `${dt.notification?.temperature || "-"} F`;
            d["Humidity"] = `${dt.notification?.windSpeed || "-"} g/kg`;
            for (const [key, value] of Object.entries(
                dt.notification.metadata || {},
            )) {
                d[`${key} *`] = value;
            }
        }
        return d;
    }, [notificationQuery.data, props.expanded]);

    if (!notificationQuery.data) {
        return null;
    }

    return (
        <div className="grid grid-cols-4 gap-y-2 gap-x-4 text-sm">
            {Object.entries(data).map(([key, value]) => (
                <div key={key} className="contents">
                    <div className="text-gray-600">{key}</div>
                    <div className="font-semibold">{value}</div>
                </div>
            ))}
        </div>
    );
};

interface EpaSepReportFormProps {
    epaForm: EpaForm;
    onClose: () => void;
    readOnly: boolean;
}

const EpaSepReportForm = (props: EpaSepReportFormProps) => {
    const { form, submit } = useEpaForm(props.epaForm, props.onClose);

    return (
        <FormProvider {...form}>
            <form onSubmit={submit}>
                <SubmitToCdxNotice />
                <FormSection>
                    <div className="w-full grid grid-cols-8 gap-12">
                        <p className="col-span-6 text-pretty">
                            Are you the owner, operator, or responsible official
                            (when applicable) of an oil and natural gas facility
                            (e.g., an individual well site, centralized
                            production facility, natural gas processing plant,
                            or compression station) located within 50 meters of
                            the latitude and longitude provided in the EPA
                            notification?
                        </p>
                        <YesNoQuestion
                            fieldName="responsibleForEmission"
                            disabled={props.readOnly}
                        />
                    </div>
                </FormSection>
                <FormSection
                    title="Facility information"
                    description="If the EPA ID# or API Well ID# in the notification was incorrect, please input the correct ID in the field below."
                >
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <FacilityInformationIdField disabled={props.readOnly} />
                        <p>Facility name*</p>
                        <TextField
                            fieldName="facilityName"
                            disabled={props.readOnly}
                        />
                        <p>Address/City/State/Zip*</p>
                        <TextField
                            fieldName="address"
                            disabled={props.readOnly}
                        />
                    </div>
                </FormSection>
                <FormSection
                    title="Demonstrable error"
                    description="Provide evidence and a statement if you believe the notification contains a clear error."
                >
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <p>
                            Do you assert a demonstrable error in the
                            notification?
                        </p>
                        <YesNoQuestion
                            fieldName="assertDemonstrableError"
                            disabled={props.readOnly}
                        />
                        <p>Upload evidence</p>
                        <EpaFormUploadField
                            uploadType="DEMONSTRABLE_ERROR"
                            form={props.epaForm}
                        />
                        <p>
                            Statement of Demonstrable Error (required if
                            asserting a demonstrable error)
                        </p>
                        <TextArea
                            fieldName="statementDemonstrableError"
                            disabled={props.readOnly}
                        />
                    </div>
                </FormSection>
                <FormSection
                    title="Applicability and identification"
                    description="Provide evidence and a statement if you believe the notification contains a clear error."
                >
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <p className="text-balanced">
                            Is there an effected facility or associated
                            equipment subject to regulation under NSPS OOOO,
                            NSPS OOOOa, NSPS OOOOb, and/or a State or Federal
                            Plan implementing OOOOc at this oil and gas
                            facility?*
                        </p>
                        <YesNoQuestion
                            fieldName="affectedFacility"
                            disabled={props.readOnly}
                        />
                        <p>
                            Did you identify the source of the super emitter
                            event?*
                        </p>
                        <YesNoQuestion
                            fieldName="identifiedSource"
                            disabled={props.readOnly}
                        />
                    </div>
                </FormSection>
                <FormSection
                    title="Search for source"
                    description="Provide details about the applicable investigation."
                >
                    <p className="mb-2">
                        Did you conduct the applicable investigation listed in
                        (d)(2)(i)-(v)?
                    </p>
                    <CheckboxField
                        fieldName="investigationSteps"
                        nestedIndex={0}
                        label="(i) Review ay maintenance activities or process activities"
                        disabled={props.readOnly}
                    />
                    <CheckboxField
                        fieldName="investigationSteps"
                        nestedIndex={1}
                        label="(ii) Review all monitoring data from control devices."
                        disabled={props.readOnly}
                    />
                    <CheckboxField
                        fieldName="investigationSteps"
                        nestedIndex={2}
                        label="(iii) Review the results of a fugitive emissions survey or periodic screening event"
                        disabled={props.readOnly}
                    />
                    <CheckboxField
                        fieldName="investigationSteps"
                        nestedIndex={3}
                        label="(iv) Review continuous monitoring data"
                        disabled={props.readOnly}
                    />
                    <CheckboxField
                        fieldName="investigationSteps"
                        nestedIndex={4}
                        label="(v) Screen the entire oil and natural gas facility with OGI, Method 21 or an alternative test method"
                        disabled={props.readOnly}
                    />
                    <hr className="my-4" />
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <p>
                            Alternative Test Method(s) approved per
                            §60.5398b(d), such as MATM-XXXX
                        </p>
                        <TextField
                            fieldName="alternateTestMethods"
                            disabled={props.readOnly}
                        />
                        <p>What day was this investigation?*</p>
                        <DateField
                            fieldName="investigationDate"
                            disabled={props.readOnly}
                        />
                        <p>What time?</p>
                        <TimeField
                            fieldName="investigationTime"
                            disabled={props.readOnly}
                        />
                        <p>What time zone?</p>
                        <TextField
                            fieldName="investigationTimezone"
                            disabled={props.readOnly}
                        />
                    </div>
                    <hr className="my-4" />
                    <CheckboxField
                        fieldName="certifiesAllInvestigationsDone"
                        label="I certify that all applicable investigations specified in paragraph (d)(6)(i) through (v) of this section have been conducted for all affected facilities and associated equipment subjects to this subpart that are at this oil and natural gas facility. *"
                        disabled={props.readOnly}
                    />
                </FormSection>
                <FormSection
                    title="Evaluation of source"
                    description="Provide details about the source and applicable regulations."
                >
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <p>What was the source of the emission?*</p>
                        <TextField
                            fieldName="sourceOfEmission"
                            disabled={props.readOnly}
                        />
                        <p>
                            Is there source subject to regulation under NSPS
                            OOO, NSPS OOOOa, NSPS OOOb, and/ore a State, Tribal
                            or Federal plan implementing OOOc?*
                        </p>
                        <div>
                            <CheckboxField
                                fieldName="subjectToRegulation"
                                nestedIndex={0}
                                label="OOOO"
                                disabled={props.readOnly}
                            />
                            <CheckboxField
                                fieldName="subjectToRegulation"
                                nestedIndex={1}
                                label="OOOOa"
                                disabled={props.readOnly}
                            />
                            <CheckboxField
                                fieldName="subjectToRegulation"
                                nestedIndex={2}
                                label="OOOOb"
                                disabled={props.readOnly}
                            />
                            <CheckboxField
                                fieldName="subjectToRegulation"
                                nestedIndex={3}
                                label="OOOOc"
                                disabled={props.readOnly}
                            />
                            <CheckboxField
                                fieldName="subjectToRegulation"
                                nestedIndex={4}
                                label="None"
                                disabled={props.readOnly}
                            />
                        </div>
                        <p>
                            Identify by citation the applicable regulation(s)
                            within each applicable subpart
                        </p>
                        <TextArea
                            fieldName="citationOfRegulation"
                            disabled={props.readOnly}
                        />
                    </div>
                </FormSection>
                <FormSection title="Status and response plan" description="">
                    <div className="w-full grid grid-cols-[3fr_2fr] gap-x-12 gap-y-4">
                        <p>Is this emission event ongoing?*</p>
                        <YesNoQuestion
                            fieldName="eventStillOngoing"
                            disabled={props.readOnly}
                        />
                        <div>
                            <p>What day did the emission event end?</p>
                            <p className="text-gray-400">
                                Required if the previous question was answered
                                "No". If the date is unknown, provide an
                                estimate.
                            </p>
                        </div>
                        <DateField
                            fieldName="eventEndDate"
                            disabled={props.readOnly}
                        />
                        <div>
                            <p>Is the above date an estimate?</p>
                            <p className="text-gray-400">
                                Required if the emission is ended.
                            </p>
                        </div>
                        <YesNoQuestion
                            fieldName="isEstimate"
                            disabled={props.readOnly}
                        />
                        <p>What time?</p>
                        <TimeField
                            fieldName="eventEndTime"
                            disabled={props.readOnly}
                        />
                        <p>What time zone?</p>
                        <TextField
                            fieldName="investigationTimezone"
                            disabled={props.readOnly}
                        />
                        <div>
                            <p>Targeted end date</p>
                            <p className="text-gray-400">
                                Required if the emission is still ongoing.
                            </p>
                        </div>
                        <DateField
                            fieldName="targetedEndDate"
                            disabled={props.readOnly}
                        />
                        <p>
                            Provide a short narrative of your plan to end the
                            super-emitter event.*
                        </p>
                        <TextArea
                            fieldName="planForEvent"
                            disabled={props.readOnly}
                        />
                        <p>Response narrative attachment(s)</p>
                        <EpaFormUploadField
                            uploadType="STATUS_AND_RESPONSE_PLAN"
                            form={props.epaForm}
                        />
                    </div>
                </FormSection>
                <SubmitToCdxNotice />
                {!props.readOnly && (
                    <>
                        <hr className="my-4" />
                        <div className="h-16 flex items-start gap-4">
                            <PrimaryButton
                                onClick={(e) => {
                                    if (
                                        confirm(
                                            "Are you sure you want to proceed? The form will become read-only after this change.",
                                        )
                                    ) {
                                        form.setValue("markAsSubmitted", true);
                                        submit(e);
                                    }
                                }}
                            >
                                Save and mark as submitted
                            </PrimaryButton>
                            <SecondaryButton
                                onClick={(e) => {
                                    form.setValue("markAsSubmitted", false);
                                    submit(e);
                                }}
                            >
                                Save and continue later
                            </SecondaryButton>
                            <SecondaryButton
                                onClick={() => {
                                    if (
                                        form.formState.isDirty &&
                                        !confirm(
                                            "Are you sure you want to close this form? All unsaved changes will be lost.",
                                        )
                                    ) {
                                        return;
                                    }
                                    props.onClose();
                                }}
                            >
                                Cancel
                            </SecondaryButton>
                        </div>
                    </>
                )}
            </form>
        </FormProvider>
    );
};

interface EpaFormModalProps {
    selectedEvent?: string;
    onClose: () => void;
}

export const EpaFormModal = (props: EpaFormModalProps) => {
    const [headerExpanded, setHeaderExpanded] = useState(false);
    const apiClient = useEpaSepApiClient();
    const notificationQuery = useQuery({
        queryKey: ["epaSepNotification", props.selectedEvent],
        queryFn: async () => {
            return await apiClient.epaSepNotificationsRetrieve({
                id: props.selectedEvent,
            });
        },
        refetchOnWindowFocus: false,
        enabled: !!props.selectedEvent,
        staleTime: 0,
    });

    const formDataQuery = useQuery({
        queryKey: ["epaSepFormData", notificationQuery.data?.epaForm],
        queryFn: async () => {
            return await apiClient.epaSepFormRetrieve({
                id: notificationQuery.data?.epaForm,
            });
        },
        refetchOnWindowFocus: false,
        enabled: !!notificationQuery.data?.epaForm,
        staleTime: 0,
    });

    const readOnly = ["COMPLETE", "SUBMITTED_TO_EPA"].includes(
        notificationQuery.data?.status,
    );

    return (
        <FullscreenModalBase
            visible={!!props.selectedEvent}
            className="fixed inset-0 bg-ae-gray-100 scroll-smooth overflow-auto w-screen h-screen overscroll-none"
            onClose={() => {
                if (
                    !readOnly &&
                    confirm(
                        "Are you sure you want to close this form? All unsaved changes will be lost.",
                    )
                ) {
                    props.onClose();
                } else {
                    props.onClose();
                }
            }}
        >
            <div className="sticky top-0 z-20 pb-6 mb-6 bg-ae-gray-250 pt-8 border-b border-b-slate-400 group">
                <div className="max-w-[800px] mx-auto transition-all">
                    <div className="flex justify-between">
                        <p className="text-stone-900 text-lg font-semibold mb-6">
                            EPA Super Emitter Program Notification
                        </p>
                        <button
                            type="button"
                            className="w-8 h-8"
                            onClick={props.onClose}
                        >
                            <FontAwesomeIcon icon={faX} className="w-4" />
                        </button>
                    </div>
                    <EpaSepNotificationInformation
                        epaNotificationId={props.selectedEvent}
                        expanded={headerExpanded}
                    />
                </div>
                <button
                    title="Show more EPA data"
                    onClick={() => setHeaderExpanded((v) => !v)}
                    className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-ae-gray-250 px-6 py-1 rounded hover:bg-ae-neutral-200"
                >
                    <div className="absolute right-0 bottom-0 w-full h-1/2 border border-slate-400 border-t-0 rounded-b" />
                    <FontAwesomeIcon
                        icon={headerExpanded ? faChevronsUp : faChevronsDown}
                        className="w-3"
                    />
                </button>
            </div>
            <div
                className="relative container mx-auto max-w-[1200px] w-full h-full"
                onScroll={(e) => console.log("scroll ", e)}
            >
                <div className="max-w-[800px] mx-auto text-neutral-600">
                    {formDataQuery.data ? (
                        <EpaSepReportForm
                            epaForm={formDataQuery.data}
                            onClose={props.onClose}
                            readOnly={readOnly}
                        />
                    ) : (
                        <LoadingIndicator />
                    )}
                </div>
            </div>
        </FullscreenModalBase>
    );
};
