/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NonDetectGeometry } from './NonDetectGeometry';
import {
    NonDetectGeometryFromJSON,
    NonDetectGeometryFromJSONTyped,
    NonDetectGeometryToJSON,
} from './NonDetectGeometry';
import type { Scene } from './Scene';
import {
    SceneFromJSON,
    SceneFromJSONTyped,
    SceneToJSON,
} from './Scene';

/**
 * Serializer for the NonDetect model.
 * @export
 * @interface NonDetect
 */
export interface NonDetect {
    /**
     * 
     * @type {string}
     * @memberof NonDetect
     */
    readonly id: string;
    /**
     * 
     * @type {Scene}
     * @memberof NonDetect
     */
    scene: Scene;
    /**
     * 
     * @type {Date}
     * @memberof NonDetect
     */
    readonly createdAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof NonDetect
     */
    readonly updatedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof NonDetect
     */
    timestamp: Date;
    /**
     * 
     * @type {NonDetectGeometry}
     * @memberof NonDetect
     */
    geometry: NonDetectGeometry;
}

/**
 * Check if a given object implements the NonDetect interface.
 */
export function instanceOfNonDetect(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('scene' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    if (!('timestamp' in value)) return false;
    if (!('geometry' in value)) return false;
    return true;
}

export function NonDetectFromJSON(json: any): NonDetect {
    return NonDetectFromJSONTyped(json, false);
}

export function NonDetectFromJSONTyped(json: any, ignoreDiscriminator: boolean): NonDetect {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'scene': SceneFromJSON(json['scene']),
        'createdAt': (json['created_at'] == null ? null : new Date(json['created_at'])),
        'updatedAt': (json['updated_at'] == null ? null : new Date(json['updated_at'])),
        'timestamp': (new Date(json['timestamp'])),
        'geometry': NonDetectGeometryFromJSON(json['geometry']),
    };
}

export function NonDetectToJSON(value?: Omit<NonDetect, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'scene': SceneToJSON(value['scene']),
        'timestamp': ((value['timestamp']).toISOString()),
        'geometry': NonDetectGeometryToJSON(value['geometry']),
    };
}

