/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminInfrastructureImportItemShape } from './AdminInfrastructureImportItemShape';
import {
    AdminInfrastructureImportItemShapeFromJSON,
    AdminInfrastructureImportItemShapeFromJSONTyped,
    AdminInfrastructureImportItemShapeToJSON,
} from './AdminInfrastructureImportItemShape';
import type { DataProvider } from './DataProvider';
import {
    DataProviderFromJSON,
    DataProviderFromJSONTyped,
    DataProviderToJSON,
} from './DataProvider';

/**
 * Serializer for the Scene model.
 * @export
 * @interface Scene
 */
export interface Scene {
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    readonly id: string;
    /**
     * 
     * @type {DataProvider}
     * @memberof Scene
     */
    dataProvider: DataProvider;
    /**
     * 
     * @type {Date}
     * @memberof Scene
     */
    readonly createdAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Scene
     */
    readonly updatedAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    sceneId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    secondaryDataSource?: string;
    /**
     * 
     * @type {Date}
     * @memberof Scene
     */
    timestamp: Date;
    /**
     * 
     * @type {AdminInfrastructureImportItemShape}
     * @memberof Scene
     */
    geometry?: AdminInfrastructureImportItemShape;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    publishedPlumeCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    cloudCoverPct?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    cloudy?: boolean;
    /**
     * 
     * @type {any}
     * @memberof Scene
     */
    data?: any;
    /**
     * 
     * @type {Date}
     * @memberof Scene
     */
    lastProcessedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    detectionLimit?: number;
}

/**
 * Check if a given object implements the Scene interface.
 */
export function instanceOfScene(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('dataProvider' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    if (!('sceneId' in value)) return false;
    if (!('timestamp' in value)) return false;
    return true;
}

export function SceneFromJSON(json: any): Scene {
    return SceneFromJSONTyped(json, false);
}

export function SceneFromJSONTyped(json: any, ignoreDiscriminator: boolean): Scene {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dataProvider': DataProviderFromJSON(json['data_provider']),
        'createdAt': (json['created_at'] == null ? null : new Date(json['created_at'])),
        'updatedAt': (json['updated_at'] == null ? null : new Date(json['updated_at'])),
        'name': json['name'] == null ? undefined : json['name'],
        'sceneId': json['scene_id'],
        'secondaryDataSource': json['secondary_data_source'] == null ? undefined : json['secondary_data_source'],
        'timestamp': (new Date(json['timestamp'])),
        'geometry': json['geometry'] == null ? undefined : AdminInfrastructureImportItemShapeFromJSON(json['geometry']),
        'publishedPlumeCount': json['published_plume_count'] == null ? undefined : json['published_plume_count'],
        'cloudCoverPct': json['cloud_cover_pct'] == null ? undefined : json['cloud_cover_pct'],
        'cloudy': json['cloudy'] == null ? undefined : json['cloudy'],
        'data': json['data'] == null ? undefined : json['data'],
        'lastProcessedAt': json['last_processed_at'] == null ? undefined : (new Date(json['last_processed_at'])),
        'detectionLimit': json['detection_limit'] == null ? undefined : json['detection_limit'],
    };
}

export function SceneToJSON(value?: Omit<Scene, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data_provider': DataProviderToJSON(value['dataProvider']),
        'name': value['name'],
        'scene_id': value['sceneId'],
        'secondary_data_source': value['secondaryDataSource'],
        'timestamp': ((value['timestamp']).toISOString()),
        'geometry': AdminInfrastructureImportItemShapeToJSON(value['geometry']),
        'published_plume_count': value['publishedPlumeCount'],
        'cloud_cover_pct': value['cloudCoverPct'],
        'cloudy': value['cloudy'],
        'data': value['data'],
        'last_processed_at': value['lastProcessedAt'] == null ? undefined : ((value['lastProcessedAt'] as any).toISOString()),
        'detection_limit': value['detectionLimit'],
    };
}

