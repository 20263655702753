/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EpaOperatorNotificationRequest } from './EpaOperatorNotificationRequest';
import {
    EpaOperatorNotificationRequestFromJSON,
    EpaOperatorNotificationRequestFromJSONTyped,
    EpaOperatorNotificationRequestToJSON,
} from './EpaOperatorNotificationRequest';

/**
 * Serializer for the EpaForm model.
 * @export
 * @interface EpaFormRequest
 */
export interface EpaFormRequest {
    /**
     * 
     * @type {EpaOperatorNotificationRequest}
     * @memberof EpaFormRequest
     */
    operatorNotification: EpaOperatorNotificationRequest;
    /**
     * 
     * @type {boolean}
     * @memberof EpaFormRequest
     */
    responsibleForEmission?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    epaId?: string;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    apiWellId?: string;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    facilityName?: string;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    address?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EpaFormRequest
     */
    assertDemonstrableError?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    statementDemonstrableError?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EpaFormRequest
     */
    affectedFacility?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EpaFormRequest
     */
    identifiedSource?: boolean;
    /**
     * 
     * @type {any}
     * @memberof EpaFormRequest
     */
    investigationSteps?: any;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    alternateTestMethods?: string;
    /**
     * 
     * @type {Date}
     * @memberof EpaFormRequest
     */
    investigationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    investigationTime?: string;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    investigationTimezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EpaFormRequest
     */
    certifiesAllInvestigationsDone?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    sourceOfEmission?: string;
    /**
     * 
     * @type {any}
     * @memberof EpaFormRequest
     */
    subjectToRegulation?: any;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    citationOfRegulation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EpaFormRequest
     */
    eventStillOngoing?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EpaFormRequest
     */
    eventEndDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EpaFormRequest
     */
    isEstimate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    eventEndTime?: string;
    /**
     * 
     * @type {Date}
     * @memberof EpaFormRequest
     */
    targetedEndDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EpaFormRequest
     */
    planForEvent?: string;
}

/**
 * Check if a given object implements the EpaFormRequest interface.
 */
export function instanceOfEpaFormRequest(value: object): boolean {
    if (!('operatorNotification' in value)) return false;
    return true;
}

export function EpaFormRequestFromJSON(json: any): EpaFormRequest {
    return EpaFormRequestFromJSONTyped(json, false);
}

export function EpaFormRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EpaFormRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'operatorNotification': EpaOperatorNotificationRequestFromJSON(json['operator_notification']),
        'responsibleForEmission': json['responsible_for_emission'] == null ? undefined : json['responsible_for_emission'],
        'epaId': json['epa_id'] == null ? undefined : json['epa_id'],
        'apiWellId': json['api_well_id'] == null ? undefined : json['api_well_id'],
        'facilityName': json['facility_name'] == null ? undefined : json['facility_name'],
        'address': json['address'] == null ? undefined : json['address'],
        'assertDemonstrableError': json['assert_demonstrable_error'] == null ? undefined : json['assert_demonstrable_error'],
        'statementDemonstrableError': json['statement_demonstrable_error'] == null ? undefined : json['statement_demonstrable_error'],
        'affectedFacility': json['affected_facility'] == null ? undefined : json['affected_facility'],
        'identifiedSource': json['identified_source'] == null ? undefined : json['identified_source'],
        'investigationSteps': json['investigation_steps'] == null ? undefined : json['investigation_steps'],
        'alternateTestMethods': json['alternate_test_methods'] == null ? undefined : json['alternate_test_methods'],
        'investigationDate': json['investigation_date'] == null ? undefined : (new Date(json['investigation_date'])),
        'investigationTime': json['investigation_time'] == null ? undefined : json['investigation_time'],
        'investigationTimezone': json['investigation_timezone'] == null ? undefined : json['investigation_timezone'],
        'certifiesAllInvestigationsDone': json['certifies_all_investigations_done'] == null ? undefined : json['certifies_all_investigations_done'],
        'sourceOfEmission': json['source_of_emission'] == null ? undefined : json['source_of_emission'],
        'subjectToRegulation': json['subject_to_regulation'] == null ? undefined : json['subject_to_regulation'],
        'citationOfRegulation': json['citation_of_regulation'] == null ? undefined : json['citation_of_regulation'],
        'eventStillOngoing': json['event_still_ongoing'] == null ? undefined : json['event_still_ongoing'],
        'eventEndDate': json['event_end_date'] == null ? undefined : (new Date(json['event_end_date'])),
        'isEstimate': json['is_estimate'] == null ? undefined : json['is_estimate'],
        'eventEndTime': json['event_end_time'] == null ? undefined : json['event_end_time'],
        'targetedEndDate': json['targeted_end_date'] == null ? undefined : (new Date(json['targeted_end_date'])),
        'planForEvent': json['plan_for_event'] == null ? undefined : json['plan_for_event'],
    };
}

export function EpaFormRequestToJSON(value?: EpaFormRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operator_notification': EpaOperatorNotificationRequestToJSON(value['operatorNotification']),
        'responsible_for_emission': value['responsibleForEmission'],
        'epa_id': value['epaId'],
        'api_well_id': value['apiWellId'],
        'facility_name': value['facilityName'],
        'address': value['address'],
        'assert_demonstrable_error': value['assertDemonstrableError'],
        'statement_demonstrable_error': value['statementDemonstrableError'],
        'affected_facility': value['affectedFacility'],
        'identified_source': value['identifiedSource'],
        'investigation_steps': value['investigationSteps'],
        'alternate_test_methods': value['alternateTestMethods'],
        'investigation_date': value['investigationDate'] == null ? undefined : ((value['investigationDate'] as any).toISOString().substring(0,10)),
        'investigation_time': value['investigationTime'],
        'investigation_timezone': value['investigationTimezone'],
        'certifies_all_investigations_done': value['certifiesAllInvestigationsDone'],
        'source_of_emission': value['sourceOfEmission'],
        'subject_to_regulation': value['subjectToRegulation'],
        'citation_of_regulation': value['citationOfRegulation'],
        'event_still_ongoing': value['eventStillOngoing'],
        'event_end_date': value['eventEndDate'] == null ? undefined : ((value['eventEndDate'] as any).toISOString().substring(0,10)),
        'is_estimate': value['isEstimate'],
        'event_end_time': value['eventEndTime'],
        'targeted_end_date': value['targetedEndDate'] == null ? undefined : ((value['targetedEndDate'] as any).toISOString().substring(0,10)),
        'plan_for_event': value['planForEvent'],
    };
}

