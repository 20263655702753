/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NonDetect,
  PaginatedNonDetectList,
} from '../models/index';
import {
    NonDetectFromJSON,
    NonDetectToJSON,
    PaginatedNonDetectListFromJSON,
    PaginatedNonDetectListToJSON,
} from '../models/index';

export interface NondetectsNondectListRequest {
    infrastructure?: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    timestampAfter?: Date;
    timestampBefore?: Date;
}

export interface NondetectsNondectRetrieveRequest {
    id: string;
}

/**
 * 
 */
export class NondetectsApi extends runtime.BaseAPI {

    /**
     * API endpoint that allows NonDetects to be viewed.
     */
    async nondetectsNondectListRaw(requestParameters: NondetectsNondectListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNonDetectList>> {
        const queryParameters: any = {};

        if (requestParameters['infrastructure'] != null) {
            queryParameters['infrastructure'] = requestParameters['infrastructure'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['timestampAfter'] != null) {
            queryParameters['timestamp_after'] = (requestParameters['timestampAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['timestampBefore'] != null) {
            queryParameters['timestamp_before'] = (requestParameters['timestampBefore'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/nondetects/nondect/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNonDetectListFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows NonDetects to be viewed.
     */
    async nondetectsNondectList(requestParameters: NondetectsNondectListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNonDetectList> {
        const response = await this.nondetectsNondectListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * API endpoint that allows NonDetects to be viewed.
     */
    async nondetectsNondectRetrieveRaw(requestParameters: NondetectsNondectRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NonDetect>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling nondetectsNondectRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // TokenAuthentication authentication
        }

        const response = await this.request({
            path: `/api/v1/nondetects/nondect/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NonDetectFromJSON(jsonValue));
    }

    /**
     * API endpoint that allows NonDetects to be viewed.
     */
    async nondetectsNondectRetrieve(requestParameters: NondetectsNondectRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NonDetect> {
        const response = await this.nondetectsNondectRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
